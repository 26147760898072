









































































































































































import Vue from "vue";
import { AxiosError } from "axios";
import TallaCamisetaCarreraService, { TallaCamisetaDto, TallaCamisetaEditDto } from "@/api/carrera/detalle/TallaCamisetaCarreraService";
type Stock = {
  stockBase: number;
  stockSequence: number;
};
export default Vue.extend({
  data() {
    return {
      saving: false,
      isAvanzado: true,
      datos: [],
      capacidad: 0,
      restante: 0,
      liberado: 0,
      estadoOptions: [
        { value: "1", text: "Activo" },
        { value: "2", text: "Inactivo" }
      ],
      tallasRestantes: [],
      selectedItems: [],
    };
  },
  methods: {
    goInventario(articuloID: number) {
      this.$router.push(`/admin/inventario/general/articulo/${articuloID}`);
    },
    getTallasEditables() {
      this.selectedItems = [];
      TallaCamisetaCarreraService.getTallasEditables(this.$route.params.id)
        .then(response => {
          this.datos = response;
          //this.calculateStokc();
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Tallas Editables",
            text: "No se pudo obtener"
          });
        });
    },
    save() {
      if (this.saving) {
        return;
      }
      const dto = { tallasCamisetas: this.datos };
      this.saving = true;
      TallaCamisetaCarreraService.saveTallasEditables(
        this.$route.params.id,
        dto
      )
        .then(() => {
          this.getTallasEditables();
          this.obtenerTallasRestantes();
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
            title: "Tallas",
            text: "Guardadas"
          });
        })
        .catch((error: AxiosError) => {
          let mensaje = "No guardadas";
          if (error.response?.data && error.response.data.message) {
            mensaje = error.response.data.message;
          }
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Tallas",
            text: mensaje
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    calculateStokc() {
      for (const i in this.datos) {
        const item = this.datos[i] as Stock;
        this.capacidad += item.stockBase;
        this.restante += item.stockSequence;
      }
      this.liberado = this.capacidad - this.restante;
    },
    agregarTallas() {
      if(this.saving) return;
      if (this.selectedItems.length) {
        const tallasAgregar: TallaCamisetaDto[] = [];

        this.selectedItems.forEach((item: any) => {
          const index = this.datos.findIndex(
            (element: any) => element.talla.id === item.id
          );
          // SE VERIFICA QUE LA TALLA NO APAREZCA EN LA LISTA SINO NO SE AGREGA
          if (index === -1) {
            tallasAgregar.push({
              talla: item,
              stockBase: 0,
              stockSequence: 0,
              estado: "1",
              articuloInventario: {
                estadoStock: "1",
                estadoStockDisplay: "Agotado",
              },
            });
          }
        });

        TallaCamisetaCarreraService.saveTallasEditables(
          this.$route.params.id,
          { tallasCamisetas: tallasAgregar }
        )
          .then(() => {
            this.getTallasEditables();
            this.obtenerTallasRestantes();
            this.$swal({
              toast: true,
              position: "center",
              showConfirmButton: false,
              timer: 2000,
              icon: "success",
              title: "Tallas",
              text: "Tallas Agregadas Exitosamente"
            });
          })
          .catch(() => {
            this.$swal({
              toast: true,
              position: "center",
              showConfirmButton: false,
              timer: 2000,
              icon: "error",
              title: "Tallas",
              text: "No guardadas"
            });
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
    obtenerTallasRestantes() {
      this.selectedItems = [];
      TallaCamisetaCarreraService.getTallasRestantes(this.$route.params.id)
        .then(response => {
          this.tallasRestantes = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Tallas Restantes",
            text: "No se pudo obtener"
          });
        });
    },
    eliminarTallaCamiseta(idTallaCamiseta: number) {
      TallaCamisetaCarreraService.deleteTallaCamiseta(this.$route.params.id,idTallaCamiseta)
        .then(() => {
          this.getTallasEditables();
          this.obtenerTallasRestantes();
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "success",
            title: "Tallas",
            text: "Talla Eliminada"
          });
        })
        .catch((error) => {
          let mensaje = "No eliminada";

          if (error.response?.data && error.response.data.message) {
            mensaje = error.response.data.message;
          }

          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Error en eliminacion",
            text: mensaje
          });
        });
      
    }
  },
  mounted() {
    this.getTallasEditables();
    this.obtenerTallasRestantes();
  },
  created() {
    console.log("Created");
  }
});
