






















































import CuponAdd from "./CuponAdd.vue";
import Vue from "vue";
import CuponEventoService, {
  GetCuponEvento
} from "@/api/evento/CuponEventoService";
import CuponMultipleCanjeados from "./CuponMultipleCanjeados.vue";

export default Vue.extend({
  components: {
    CuponAdd,
    CuponMultipleCanjeados
  },
  data() {
    return {
      dialog: false,
      dialogCanjeados: false,
      headers: [
        { text: "Id", value: "id" },
        { text: "Código", value: "codigo" },
        { text: "Disponibles", value: "disponible" },
        { text: "% Desc.", value: "porcentajeDescuento" },
        { text: "Motivo", value: "motivo" },
        { text: "Creado Por", value: "creadoPor.nombre" },
        { text: "Canjeado Por", value: "actions" }
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        //{ text: "Acción", value: "actions", sortable: false }
      ],
      esMultiple: true,
      idEvento: "0",
      datos: [] as GetCuponEvento[],
      item: null
    };
  },
  methods: {    
    showCanjeados(item: any) {
      this.item = item;
      this.dialogChangeCanjeados(true);
    },/*
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    */
    newCupon() {
      this.idEvento = this.$route.params.id;
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    dialogChangeCanjeados(value: boolean) {
      this.dialogCanjeados = value;      
    },
    getAll() {
      CuponEventoService.getAllMultiples(this.$route.params.id)
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Cupones",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {}
});
