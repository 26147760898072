






















































































































































//import CategoriaDistanciaCarreraAdd from "./DistanciaCarreraAdd.vue";
import Vue from "vue";
/*
import DistanciaCarreraService, {
  DistanciaCarreraCreateDto
} from "@/api/carrera/detalle/DistanciaCarrera.service";
*/

//import DistanciaService, { DistanciaCreateDto } from "@/api/DistanciaService";
import PapeletaAprobar from "@/components/admin/transacciones/PapeletaAprobar.vue";
import RegistradosPendientesEntregaCambiarRegistroAdd from "./RegistrosPendienteEntregaCambiarRegistroAdd.vue";
import RegistroCarreraService, {
  RegistroCarreraCreateDto
} from "@/api/carrera/detalle/RegistroCarreraService";
import XLSX from "xlsx";
import moment from "moment";
import { PedidoDto } from "@/api/pedido/PedidoService";
import AuthStore from "@/store/modules/AuthStore";
import { isIn } from "class-validator";

export default Vue.extend({
  components: {
    //CategoriaDistanciaCarreraAdd
    PapeletaAprobar,
    RegistradosPendientesEntregaCambiarRegistroAdd,
  },
  data() {
    return {
      search: "",
      dialog: false,
      dialog2: false,
      /*
      distanciasBase: [] as DistanciaCreateDto[],
      dato: {
        nombre: ""
      } as DistanciaCarreraCreateDto,
      */
      headers: [
        { text: "#Rg", value: "id" },
        { text: "#Pd", value: "pedido.id" },
        { text: "Orden", value: "secuencial"},
        { text: "Identificación", value: "persona.identificacion" },
        { text: "Nombre", value: "persona.nombre" },
        { text: "Apellido", value: "persona.apellido" },
        { text: "Distancia", value: "distancia.nombre" },
        { text: "Categoria", value: "categoria.nombre" },
        { text: "Talla", value: "tallaCamiseta.talla.nombre" },
        { text: "Modelo", value: "tallaCamiseta.modelo" },
        { text: "Personalización", value: "personalizacionCamiseta" },
        { text: "#Camisa", value: "numeroCamiseta" },
        { text: "#Chip", value: "chipCronometraje.codigo" },
        { text: "$$$", value: "total" },
        { text: "Estados", value: "estados", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        //{ text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as RegistroCarreraCreateDto[],
      totalRecaudado: "0.00",
      dato: {
        id: 0
      } as PedidoDto,
      dato2: {
        id: 0
      } as RegistroCarreraCreateDto
    };
  },
  methods: {
    loadTotalRecaudado() {
      let total = 0;
      for (const doc of this.datos) {
        const p = doc as any;
        total += Number(p.total);
      }
      this.totalRecaudado = total.toFixed(2);
    },
    exportExcel() {
      //const data = XLSX.utils.json_to_sheet(this.datos);
      const data = XLSX.utils.table_to_sheet(
        document.getElementById("tablita")
      );
      const workbook = XLSX.utils.book_new();
      const filename = "carrera-registros-pagados";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    setDefaultItem() {
      const newDto = {
        id: 0
      };
      this.dato = newDto;
    },
    editItem(item: PedidoDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    editItem2(item: RegistroCarreraCreateDto) {
      this.dato2 = item;
      this.dialogChange2(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    /*
    editItem(item: DistanciaCarreraCreateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    */
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    dialogChange2(value: boolean) {
      this.dialog2 = value;
    },
    getAll() {
      RegistroCarreraService.getRegistrosPagados(this.$route.params.id)
        .then(response => {
          this.datos = this.agregarSecuencialDatos(response);
          this.loadTotalRecaudado();
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Registros Pagados",
            text: "No se pudo obtener"
          });
        });
    },
    agregarSecuencialDatos(datos: RegistroCarreraCreateDto[]) {
      let secuencial = datos.length;
      for (const dato of datos) {
        dato.secuencial = secuencial;
        secuencial--;
      }
      return datos;
    },
    downloadExport() {
      RegistroCarreraService.getRegistrosPagadosExcel(this.$route.params.id)
        .then(response => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          const today = new Date();
          moment.locale("es");
          link.setAttribute(
            "download",
            `carrera-${this.$route.params.id}_${moment(today).format(
              "YYYY-MMMM-DD"
            )}.xlsx`
          ); //"carrera-reporte.xlsx"
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          console.log("no se pudo generar el documento");
        });
    },
    isInRole(roles: string[]) {
      return isIn(this.getRole, roles);
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  },
  computed: {
    getRole(): string {
      if (AuthStore.getLoggedIn && AuthStore.getRole) {
        return AuthStore.getRole;
      } else {
        return "";
      }
    }
  }
});
