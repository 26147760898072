









































































































































import Vue from "vue";
import CarreraService, { ReporteCarreraDto } from "@/api/CarreraService";
import DateTimePicker from "@/components/shared/DateTimePicker.vue";
import ReporteCarrera from "./ReporteCarrera.vue";
import CantonService from "@/api/pedido/CantonService";
import PagosServices from "@/api/PagosServices";

export default Vue.extend({
  components: {
    DateTimePicker,
    ReporteCarrera
  },
  data() {
    return {
      editedItem: {
        nombre: "",
        fechaInicioEvento: new Date().toISOString().substr(0, 10) as
          | string
          | Date,
        fechaFinEvento: new Date().toISOString().substr(0, 10) as string | Date,
        fechaInicioInscripcion: new Date().toISOString().substr(0, 10) as
          | string
          | Date,
        fechaFinInscripcion: new Date().toISOString().substr(0, 10) as
          | string
          | Date,
        //horaInicioEvento: 7.0,
        //horaFinEvento: 7.0,
        //horaInicioInscripcion: 23,
        //horaFinInscripcion: 23,

        ciudad: "",
        lugar: "",

        estado: "",
        tipoParticipante: "",
        modalidad: "",
        estadoPersonalizacion: "",

        hashtag: "",
        cupos: 0,
        direccion: "",
        linkUbicacion: "",
        plataformaPagoId: 0
      },
      estadoOptions: [
        { text: "Borrador", value: "1" },
        { text: "Publicado", value: "2" },
        { text: "Privado", value: "3" }
      ],
      tipoParticipanteOptions: [
        { text: "Hombre", value: "M" },
        { text: "Mujer", value: "F" },
        { text: "Mixto", value: "T" }
      ],
      modalidadOptions: [
        { text: "Virtual", value: "V" },
        { text: "Presencial", value: "P" },
        { text: "Presencial durante COVID", value: "PCV" }
      ],
      estadoPersonalizacionOptions: [
        { text: "Activa", value: "1" },
        { text: "Inactiva", value: "2" }
      ],
      cantones: [] as any,
      reporteCarrera: null as null | ReporteCarreraDto,
      plataformasPago: [] as any
    };
  },
  methods: {
    loadCantones() {
      this.cantones = CantonService.getCantones();
    },
    loadPlataformasPago() {
      PagosServices.getPlataformaPagos().then(response => {
        this.plataformasPago = response;
      }).catch((error) => {
        this.plataformasPago = [];
        this.$swal({
          toast: true,
          position: "center",
          showConfirmButton: false,
          timer: 2000,
          icon: "error",
          title: "Carrera",
          text: "No se pudo obtener las plataformas de pago"
        });
        
      });
    },
    loadCarrera() {
      CarreraService.getCarrera(this.$route.params.id)
        .then(response => {
          const plataformaPagoId = response.plataformaCarreras ? response.plataformaCarreras[0]?.plataformaPago?.id : 0;

          this.editedItem = {
            ...response,
            fechaInicioEvento: new Date(response.fechaInicioEvento),
            fechaFinEvento: new Date(response.fechaFinEvento),
            fechaInicioInscripcion: new Date(response.fechaInicioInscripcion),
            fechaFinInscripcion: new Date(response.fechaFinInscripcion),
            plataformaPagoId: plataformaPagoId
          };
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Carrera",
            text: "No se pudo obtener"
          });
        });
    },
    loadReporte() {
      CarreraService.getReporte(this.$route.params.id)
        .then(response => {
          this.reporteCarrera = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Carrera",
            text: "Reporte no se pudo obtener"
          });
        });
    },
    save() {
      CarreraService.save(this.editedItem)
        .then(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Carrera",
            text: "Guardada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Carrera",
            text: "No se pudo guardar"
          });
        });
    }
  },
  mounted() {
    this.loadCarrera();
    this.loadReporte();
  },
  created() {
    this.loadCantones();
    this.loadPlataformasPago();
  }
});
