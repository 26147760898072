





































import Vue from "vue";
import CuponEventoService, {
  GetCuponMultipleCanjeados
} from "@/api/evento/CuponEventoService";

export default Vue.extend({
  props: ["dialog", "item", "idEvento"],
  data() {
    return {
      respuesta: [] as GetCuponMultipleCanjeados[],
      headers: [
        { text: "#", value: "id" },
        { text: "Nombre", value:  "canjeadoPor.nombre"},
        { text: "Apellido", value: "canjeadoPor.apellido" },
        { text: "Fecha Canjeo", value: "createDate" }
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        //{ text: "Acción", value: "actions", sortable: false }
      ]
    };
  },
  mounted() {
    //this.editedItem = { ...this.dato };
    this.getCanjeados();
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChangeCanjeados", value);
    },
    close() {
      this.dialogChange(false);
    },
    getCanjeados() {
      CuponEventoService.obtenerCanjeadosCuponMultiple(this.item.id, this.$route.params.id).then((res) => {
        this.respuesta = res;
      });
    }
  },
  computed: {},
  watch: {}
});
