import httpClient from "@/api/http-client";

const ENDPOINT = "/evento/carrera";

export interface RegistroCarreraCreateDto {
  id?: number;
  //nombre: string;
  //distancia: number;
  secuencial?: number;
}

export interface RegistroPendienteEntregaEntregarDto {
  codigoChip: string;
  entregadoA: string;
  numeroCamiseta: string;
}

class RegistroCarreraService {
  getRegistros(idCarrera: string): Promise<RegistroCarreraCreateDto[]> {
    return httpClient
      .get(`${ENDPOINT}/registros/${idCarrera}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  getRegistrosPendientesPago(idCarrera: string): Promise<RegistroCarreraCreateDto[]> {
    return httpClient
      .get(`${ENDPOINT}/registros-pendientes-pago/${idCarrera}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  getRegistrosPagados(idCarrera: string): Promise<RegistroCarreraCreateDto[]> {
    return httpClient
      .get(`${ENDPOINT}/registros-pagados/${idCarrera}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  getRegistrosPagadosExcel(idCarrera: string) {
    return httpClient.get(`${ENDPOINT}/registros-pagados/excel/${idCarrera}`, {
      responseType: "blob"
    });
  }

  getRegistrosPendientesEntrega(
    idCarrera: string
  ): Promise<RegistroCarreraCreateDto[]> {
    return httpClient
      .get(`${ENDPOINT}/registros-pendientes-entrega/${idCarrera}`)
      .then(response => {
        if (response.data) {
          return response.data;
        }
      });
  }

  // se depreca por modificarRegistroPagado
  /*
  modificarRegistroPendienteEntrega(idRegistro: number, dto: any) {
    return httpClient.put(
      `${ENDPOINT}/registros-pendientes-entrega/modificar/${idRegistro}`,
      dto
    );
  }
  */

  modificarRegistroPagado(idRegistro: number, dto: any) {
    return httpClient.put(
      `${ENDPOINT}/registros-pagados/modificar/${idRegistro}`,
      dto
    );
  }

  entregarRegistroPendienteEntrega(
    idRegistro: number,
    dto: RegistroPendienteEntregaEntregarDto
  ) {
    return httpClient.put(
      `${ENDPOINT}/registros-pendientes-entrega/entregar/${idRegistro}`,
      dto
    );
  }
  /*
  save(idCarrera: string, createDto: RegistroCarreraCreateDto) {
    console.log(createDto);
    return httpClient.post(`${ENDPOINT}/editable/${idCarrera}`, createDto);
  }
  */
}
export default new RegistroCarreraService();
